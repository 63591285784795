import { QueryFunctionContext } from '@tanstack/react-query'

import { ApiService } from '../../common/helpers/fetch'
import { AccountContextProps } from '../../contexts/account-context'
import { Account, CreateAccount } from '../models'

// default configuration for service
const basePath = 'account'

const config: RequestInit = {}

export const getAccountByUsernameRequest = async (username: string) => {
  return await ApiService.get<AccountContextProps>(`${basePath}/find-by-username/${username}`, config)
}

export const getAccounts = async () => {
  return await ApiService.get<Account[]>(basePath, config)
}

export const getAccount = async ({ queryKey }: QueryFunctionContext<[string, string]>) => {
  const [, accountId] = queryKey
  return await ApiService.get<Account>(`${basePath}/${accountId}`, config)
}

export const getAccountByUsername = async ({ queryKey }: QueryFunctionContext<[string, string]>) => {
  const [, userId] = queryKey
  return getAccountByUsernameRequest(userId)
}

export const postAccount = async ({ payload }: { payload: CreateAccount }) =>
  await ApiService.post<CreateAccount, Account>(`${basePath}`, payload, config)

export const postAccountCheck = async ({ payload }: { payload: { name: string } }) =>
  await ApiService.post<{ name: string }, unknown>(`${basePath}/check`, payload, config)

export const patchAccount = async ({ accountId, payload }: { accountId?: string; payload: Partial<Account> }) =>
  await ApiService.patch<Partial<Account>, unknown>(`${basePath}/${accountId}`, payload, config)

export const deleteAccount = async ({ accountId }: { accountId: number }) =>
  await ApiService.delete<any>(`${basePath}/${accountId}`)

export const inviteUser = async ({ accountId, payload }: { accountId: string; payload: { email: string } }) =>
  await ApiService.post<any, any>(`${basePath}/${accountId}/users`, payload)

export const confirmInvite = async ({ inviteToken }: { inviteToken: string }) =>
  await ApiService.post<any, any>(`${basePath}/confirm/${inviteToken}`, {})
