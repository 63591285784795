import React, { createContext, useContext } from 'react'
import { Id, Slide, toast, ToastContainer, ToastOptions } from 'react-toastify'

const defaultOptions: ToastOptions = {
  position: 'bottom-right',
  className: 'position-fixed left-4 bottom-12',
  autoClose: 3000, // 3s
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  transition: Slide,
  theme: 'colored',
}

type ToastProviderProps = {
  children: React.ReactNode
}

interface ToastProps extends ToastOptions {
  message: string
}

type ShowToast = (config: ToastProps) => Id

const ToastContext = createContext({
  showToast: (config: ToastProps) => toast(config.message, { ...defaultOptions, ...config }),
})

export const useToast = () => {
  const context = useContext(ToastContext)

  if (!context) throw new Error('Toast context must be use inside ToastProvider')

  return context
}

const ToastProvider = (props: ToastProviderProps) => {
  const { children } = props

  const showToast: ShowToast = (config) => toast(config.message, { ...defaultOptions, ...config })

  return (
    <ToastContext.Provider value={{ showToast }}>
      <ToastContainer />
      {children}
    </ToastContext.Provider>
  )
}

export default ToastProvider
