import { useEffect } from 'react'
import { useMixpanel } from 'react-mixpanel-browser'
import { useNavigate, useSearchParams } from 'react-router-dom'

import mixpanelEvents from 'common/helpers/mixpanelEvents'
import useConfirmEmail from 'common/hooks/useConfirmEmail'
import { AuthLayout } from 'layouts/AuthLayout'

export default function ConfirmEmail() {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token') || ''
  const { confirmEmailMutation } = useConfirmEmail()

  const navigate = useNavigate()
  const mixpanel = useMixpanel()

  useEffect(() => {
    confirmEmailMutation.mutate({ token })
  }, [token])

  if (mixpanel && confirmEmailMutation.isSuccess) {
    mixpanel.track(mixpanelEvents.emailConfirmed)
  }

  return (
    <AuthLayout>
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-6 sm:p-8">
            {confirmEmailMutation.isPending && <p>Please wait...</p>}
            {confirmEmailMutation.isSuccess && (
              <>
                <h3 className="text-xl text-gray-900 md:text-2xl py-5">Thank you for confirming your email address.</h3>
                <a
                  href="/login"
                  className="w-full text-white bg-primary-400 hover:bg-primary-500 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg px-5 py-2.5 text-center"
                >
                  Click here to be taken to the login page
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </AuthLayout>
  )
}
