import {
  type BreakdownFormField,
  type BreakdownItemFormField,
  type BreakdownType,
  type DemandFunnelFormField,
  type DemandFunnelItemFormField,
  type DemandFunnelSpreadAssumptionField,
  type DemandFunnelSpreadField,
  type DemandPlanFormValuesProps,
  type DemandPlanStatus,
  type DemandSpreadField,
  type DemandYearlySpreadField,
  type FunnelSpread,
  type MonthlySpread,
} from '@helmur/common/dist/types'

// Re-export types from common
export {
  type BreakdownItemFormField,
  type BreakdownType,
  type DemandPlanStatus,
  type BreakdownFormField,
  type DemandYearlySpreadField,
  type MonthlySpread,
  type DemandSpreadField,
  type DemandFunnelSpreadAssumptionField,
  type DemandFunnelSpreadField,
  type DemandFunnelItemFormField,
  type DemandFunnelFormField,
  type FunnelSpread,
  type DemandPlanFormValuesProps as FormValuesProps,
}

export type SelectOption = {
  id: string
  name: string
}

export enum ChartTypes {
  Bars = 'Bars',
  StackedBars = 'Stacked Bars',
  Lines = 'Lines',
  Funnel = 'Funnel',
}

export type DemandPlanSearchQueryParams = {
  // Table params
  fv?: boolean // funnel process visibility toggle
  av?: boolean // show actuals checkbox
  pv?: boolean // show planned data checkbox
  cr?: boolean // show conversion rate checkbox
  bv?: string // breakdown visibility toggles
  bf?: string // breakdown filter
  ff?: string[] // funnel filter
  ai?: 0 | 1 // active tab index
  st?: 'q' | 'm' // spread type
  // Chart params
  ct?: ChartTypes // chart type
  cbf?: string // chart breakdown filter
  cff?: string[] // chart funnel filter
  cav?: 0 | 1 // chart actuals
  csf?: string // chart selected funnel
  csb?: string // chart stacked by
  csm?: string // chart selected metric
  cfs?: string // chart funnel stage
}

export type FunnelSourceCode = 'all' | 'marketing' | 'sales' | 'partner'
