import { ApiService } from '../helpers/fetch'

const basePath = 'chat'
const config: RequestInit = {}

export const invokeChat = async ({ input, threadId }: { input: string; threadId: string }) => {
  return await ApiService.post<{ input: string; threadId: string }, { content: string; demandPlanId?: string }>(
    `${basePath}/invoke/`,
    { input, threadId },
    config,
  )
}
