import React from 'react'

export interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  wrapper?: React.HTMLAttributes<HTMLDivElement>
  label?: React.LabelHTMLAttributes<HTMLLabelElement>
  options: React.DetailedHTMLProps<React.OptionHTMLAttributes<HTMLOptionElement>, HTMLOptionElement>[]
  error?: any
}

const Select = React.forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
  const { id, label, error, wrapper, options, ...restProps } = props

  return (
    <div {...wrapper} className={`flex flex-col mb-4 ${wrapper?.className ?? ''}`}>
      {label && (
        <label {...label} className={`text-16px mb-2 ${label.className ?? ''}`} htmlFor={id}>
          {label.defaultValue}
        </label>
      )}
      <select
        ref={ref}
        {...restProps}
        id={id}
        className={`border rounded-md px-3 py-[11px] text-dark-gray bg-light-gray text-16px leading-5 ${restProps.className || ''}`}
      >
        {options.map((option, index) => (
          <option key={index} {...option}>
            {option.label}
          </option>
        ))}
      </select>
      {error && <span className="mt-1 text-red-500">{error}</span>}
    </div>
  )
})

export default Select
