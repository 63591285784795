import { QueryFunctionContext } from '@tanstack/react-query'

import { ApiService } from '../../common/helpers/fetch'
import { Account } from '../models'

// default configuration for service
const basePath = 'auth'

export const getAccount = async ({ queryKey }: QueryFunctionContext<[string, string]>) => {
  const [, accountId] = queryKey
  return await ApiService.get<Account>(`${basePath}/${accountId}`)
}

export const confirmEmail = async (data: { token: string }) =>
  await ApiService.post<{ token: string }, any>(`${basePath}/confirm/email`, data)

export const getInvite = async ({ queryKey }: QueryFunctionContext<[string, string]>) => {
  const [, token] = queryKey
  return await ApiService.get<AccountInviteRequest>(`${basePath}/invite?token=${token}`)
}

type AccountInviteRequest = {
  inviteToken: string
  email: string
  name: string
  accountId: string
  invitedBy: {
    firstName: string
    lastName: string
    email: string
  }
  invitedUser: {
    email: string
  }
  account: {
    name: string
  }
}

export const confirmInvite = async (confirmInviteRequest: ConfirmInviteRequest) =>
  await ApiService.post<ConfirmInviteRequest, any>(`${basePath}/confirm/invite`, confirmInviteRequest)

export type ConfirmInviteRequest = {
  email: string
  password: string
  firstName: string
  lastName: string
  inviteToken: string
}

export const checkForgotPasswordToken = async ({ queryKey }: QueryFunctionContext<[string, string]>) => {
  const [, token] = queryKey
  return await ApiService.get<{ token: string; user: { email: string } }>(`${basePath}/forgot-password?token=${token}`)
}

export const sendForgotPassword = async (email: string) =>
  await ApiService.post<{ email: string }, any>(`${basePath}/forgot-password`, { email })

export const updatePassword = async ({ token, password }: { token: string; password: string }) =>
  await ApiService.post<{ token: string; password: string }, any>(`${basePath}/update-password`, { token, password })
