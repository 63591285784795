import { useMutation } from '@tanstack/react-query'

import { invokeChat } from '../services'

export enum CHAT_MUTATION_KEYS {
  chatMutation = 'invokeChat',
}
const useChatMutations = () => {
  return {
    invokeChat: useMutation({ mutationFn: invokeChat, mutationKey: [CHAT_MUTATION_KEYS.chatMutation] }).mutateAsync,
  }
}

export default useChatMutations
