import React from 'react'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  wrapper?: React.HTMLAttributes<HTMLDivElement>
  label?: React.LabelHTMLAttributes<HTMLLabelElement>
  error?: any
}

const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { id, label, error, wrapper, ...restProps } = props

  return (
    <div {...wrapper} className={`flex flex-col mb-4 ${wrapper?.className ?? ''}`}>
      {label && (
        <label {...label} className={`text-16px mb-2 ${label.className ?? ''}`} htmlFor={id}>
          {label.defaultValue}
        </label>
      )}
      <input
        ref={ref}
        {...restProps}
        id={id}
        className={`disabled:cursor-not-allowed border rounded-md px-3 py-[11px] text-dark-gray bg-light-gray text-16px leading-5 ${
          restProps.className || ''
        }`}
      />
      {error && <span className="mt-1 text-red-500">{error}</span>}
    </div>
  )
})

export default Input
