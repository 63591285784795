import { useNavigate } from 'react-router-dom'

import { LogoFull } from '../common/components/LogoFull'

export const AuthLayout = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate()

  return (
    <section className="grid h-screen lg:grid-cols-2 bg-white dark:bg-gray-900 overflow-hidden">
      <div className="flex flex-col py-6 bg-dark-blue">
        <div onClick={() => navigate('/')} className="self-start m-6 cursor-pointer">
          <LogoFull />
        </div>
        <div className="w-2/3 mx-auto">
          <h1 className=" text-5xl font-medium text-white">Plan & Orchestrate Your Demand With Confidence</h1>
        </div>
        <div className="w-2/3 mx-auto">
          <p className="font-light text-auth-gray lg:mb-8">
            Build realistic demand plans your teams can operate. Plan and track goals, KPIs, budgets and resources
            cross-functionally across Sales, Marketing, Product and Success so you're always in alignment.
          </p>
        </div>
        <div className="ml-auto">
          <img className="self-end max-w-full" src="https://i.ibb.co/Sn1DD7P/auth.png" alt="helmur screenshot" />
        </div>
      </div>
      <div className="bg-gray-50">{children}</div>
    </section>
  )
}
