import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import { useForm } from 'react-hook-form'
import { useMixpanel } from 'react-mixpanel-browser'
import { Link, useSearchParams } from 'react-router-dom'

import mixpanelEvents from 'common/helpers/mixpanelEvents'
import useConfirmInvite from 'common/hooks/useConfirmInvite'
import { ConfirmInviteRequest } from 'common/services/auth'
import { useAuth } from 'contexts/auth-provider'

import { AuthLayout } from '../../layouts/AuthLayout'

export default function ConfirmInvite() {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token') || ''
  const { inviteQuery, confirmInviteMutation } = useConfirmInvite(token)
  const { login } = useAuth()
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: '',
      firstName: '',
      lastName: '',
    },
  })

  const mixpanel = useMixpanel()

  if (inviteQuery.isSuccess) {
    setValue('email', inviteQuery.data.invitedUser?.email)
  }

  if (confirmInviteMutation.isSuccess) {
    const { email, password } = getValues()
    login(email, password)
  }

  const onSubmit = async (data: Omit<ConfirmInviteRequest, 'inviteToken'>) => {
    if (mixpanel) {
      mixpanel.track(mixpanelEvents.inviteConfirmed)
    }

    confirmInviteMutation.mutate({
      inviteToken: token,
      ...data,
    })
  }

  return (
    <AuthLayout>
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
              {inviteQuery.isSuccess ? `Join ${inviteQuery.data.account.name} on Helmur` : ''}
            </h1>
            <form className="space-y-4 md:space-y-6" action="#" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">
                  Your email
                </label>
                <input
                  type="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-400 focus:border-primary-400 block w-full p-2.5"
                  placeholder="name@example.com"
                  required
                  {...register('email', { required: true })}
                />
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div>
                  <label htmlFor="firstName" className="block mb-2 text-sm font-medium text-gray-900 ">
                    First Name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    autoFocus
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-400 focus:border-primary-400 block w-full p-2.5"
                    required
                    {...register('firstName', { required: true })}
                  />
                </div>
                <div>
                  <label htmlFor="lastName" className="block mb-2 text-sm font-medium text-gray-900 ">
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-400 focus:border-primary-400 block w-full p-2.5"
                    required
                    {...register('lastName', { required: true })}
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  placeholder="••••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-400 focus:border-primary-400 block w-full p-2.5 "
                  required
                  {...register('password', { required: true })}
                />
              </div>
              <div>
                <label htmlFor="confirmPassword" className="block mb-2 text-sm font-medium text-gray-900">
                  Confirm Password
                </label>
                <input
                  type="password"
                  id="confirmPassword"
                  placeholder="••••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-400 focus:border-primary-400 block w-full p-2.5 "
                  required
                  {...register('confirmPassword', {
                    required: true,
                    validate: (val: string) => {
                      if (watch('password') !== val) {
                        return 'Passwords do not match'
                      }
                    },
                  })}
                />
                {errors.confirmPassword && getValues().confirmPassword && (
                  <div
                    className="p-4 text-sm mt-2 text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
                    role="alert"
                  >
                    <span className="font-medium">{errors.confirmPassword.message}</span>
                  </div>
                )}
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="terms"
                      aria-describedby="terms"
                      type="checkbox"
                      required
                      className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 "
                    />
                  </div>
                  <div className="ml-3 text-sm flex gap-3 items-center cursor-pointer">
                    <label htmlFor="terms" className="font-light text-gray-500 cursor-pointer">
                      I accept the Terms and Conditions
                    </label>
                    <a href="#" target="_blank">
                      <ArrowTopRightOnSquareIcon className="w-6 text-green-400" />
                    </a>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="w-full text-white bg-primary-400 hover:bg-primary-500 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
              >
                Sign up
              </button>

              {confirmInviteMutation?.error?.message && (
                <div
                  className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
                  role="alert"
                >
                  <span className="font-medium">{confirmInviteMutation.error.message}</span>
                </div>
              )}

              <p className="text-sm font-light text-gray-500 ">
                Already have an account?{' '}
                <Link to="/login" className="font-medium text-primary-400 hover:underline">
                  Sign in
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </AuthLayout>
  )
}
