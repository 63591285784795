import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PrimeReactProvider } from 'primereact/api'
import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { Helmet } from 'react-helmet'
import { MixpanelProvider } from 'react-mixpanel-browser'
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'

import { GlobalLoader } from 'common/components'
import FeedbackButton from 'common/components/FeedbackButton'
import AccountProvider from 'contexts/account-context'
import { AuthProvider } from 'contexts/auth-provider'
import ConfirmationDialogProvider from 'contexts/confirmation-dialog-context'
import RunningProcessProvider from 'contexts/running-process-context'
import ToastProvider from 'contexts/toast-context'

import './assets/stylesheets/index.css'

import App from './root/App'

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  enabled: import.meta.env.VITE_SENTRY_DSN && import.meta.env.VITE_SENTRY_DSN !== '',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', import.meta.env.VITE_API_URL],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: { retry: 0 },
    mutations: { retry: 0 },
  },
})

const MIXPANEL_TOKEN = import.meta.env.VITE_MIXPANEL_TOKEN
const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <>
    <Helmet>
      <script async src="https://tally.so/widgets/embed.js"></script>
    </Helmet>
    <React.StrictMode>
      <PrimeReactProvider
        value={{
          pt: {
            tooltip: {
              root: {
                className: 'bg-gray-500 shadow-none p-0 m-0 rounded-lg border border-gray-500',
              },
              text: {
                className: 'bg-white shadow-none text-gray-800 text-sm',
              },
            },
          },
        }}
      >
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <MixpanelProvider token={MIXPANEL_TOKEN}>
              <AuthProvider>
                <AccountProvider>
                  <RunningProcessProvider>
                    <ConfirmationDialogProvider>
                      <ToastProvider>
                        <GlobalLoader />
                        <App />
                        <FeedbackButton />
                      </ToastProvider>
                    </ConfirmationDialogProvider>
                  </RunningProcessProvider>
                </AccountProvider>
              </AuthProvider>
            </MixpanelProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </BrowserRouter>
      </PrimeReactProvider>
    </React.StrictMode>
  </>,
)
