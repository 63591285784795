export const LogoFull = (): JSX.Element => (
  <svg width="160" height="30" viewBox="0 0 160 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_144_14895)">
      <path
        d="M57.2396 26.2461H52.4785V3.75H57.2396V12.6169H66.9795V3.75H71.7399V26.2461H66.9795V16.7215H57.2396V26.2461Z"
        fill="#F9FAFB"
      />
      <path
        d="M90.8787 15.1389C90.5656 13.9594 90.0434 12.9201 89.3128 12.0215C88.5817 11.1236 87.6787 10.4294 86.6033 9.93812C85.5279 9.44753 84.3327 9.2019 83.0177 9.2019C81.4093 9.2019 79.9794 9.57269 78.7267 10.3143C77.4741 11.0559 76.4926 12.0743 75.7832 13.3688C75.0729 14.6646 74.7178 16.1471 74.7178 17.8177C74.7178 19.552 75.0627 21.0765 75.7514 22.3926C76.4406 23.708 77.4166 24.7264 78.6801 25.447C79.9429 26.1677 81.4093 26.5283 83.08 26.5283C85.1259 26.5283 86.8386 26.0059 88.2164 24.9619C89.5941 23.9171 90.5236 22.4548 91.0037 20.5751H86.6817C86.4315 21.3898 85.9931 22.0272 85.3661 22.4866C84.7398 22.9461 83.9464 23.1755 82.9859 23.1755C82.1506 23.1755 81.4411 22.967 80.8566 22.5489C80.2716 22.1314 79.8333 21.5258 79.5411 20.732C79.3502 20.2148 79.2284 19.614 79.1623 18.9457H91.0348C91.2439 17.5883 91.1919 16.3197 90.8787 15.1389H90.8787ZM79.2186 16.1884C79.2941 15.6999 79.4065 15.2558 79.5566 14.8574C79.859 14.0535 80.2925 13.4424 80.8566 13.0243C81.4201 12.6068 82.1094 12.3978 82.9238 12.3978C83.9464 12.3978 84.7763 12.7374 85.4134 13.4161C86.0499 14.0948 86.3895 15.019 86.4315 16.1884H79.2186Z"
        fill="#F9FAFB"
      />
      <path d="M98.3326 26.2461H93.9482V3.75H98.3326V26.2461Z" fill="#F9FAFB" />
      <path
        d="M106.601 26.2462H102.248V9.51519H106.257V11.8963C106.862 11.0397 107.572 10.3766 108.386 9.90697C109.201 9.43669 110.161 9.2019 111.268 9.2019C112.416 9.2019 113.413 9.45768 114.259 9.96923C115.105 10.4815 115.736 11.2177 116.154 12.1785C116.821 11.2596 117.595 10.5336 118.471 10.001C119.348 9.46783 120.413 9.2019 121.666 9.2019C123.357 9.2019 124.724 9.73984 125.769 10.8151C126.812 11.8916 127.334 13.4317 127.334 15.4366V26.2462H123.012V16.8156C123.012 15.6247 122.767 14.7004 122.276 14.0427C121.785 13.3843 121.06 13.0555 120.1 13.0555C119.536 13.0555 119.014 13.207 118.534 13.5102C118.053 13.8126 117.672 14.2464 117.391 14.81C117.109 15.3744 116.968 16.074 116.968 16.909V26.2462H112.646V16.8156C112.646 15.6247 112.4 14.7004 111.91 14.0427C111.419 13.3843 110.693 13.0555 109.733 13.0555C109.149 13.0555 108.616 13.207 108.136 13.5102C107.655 13.8126 107.28 14.2464 107.008 14.81C106.737 15.3744 106.601 16.074 106.601 16.909V26.2462V26.2462Z"
        fill="#F9FAFB"
      />
      <path
        d="M141.24 19.1967V9.51514H145.562V26.2461H141.584V24.0531C141.146 24.7845 140.488 25.3847 139.611 25.8543C138.734 26.3246 137.763 26.5594 136.699 26.5594C135.592 26.5594 134.6 26.3293 133.723 25.8699C132.846 25.4111 132.157 24.706 131.656 23.7553C131.155 22.8053 130.904 21.609 130.904 20.1677V9.51514H135.258V19.3219C135.258 20.4918 135.524 21.3478 136.056 21.8912C136.589 22.4345 137.293 22.7058 138.171 22.7058C138.692 22.7058 139.189 22.5698 139.658 22.2985C140.128 22.0272 140.509 21.6246 140.801 21.092C141.093 20.5595 141.24 19.9282 141.24 19.1967H141.24Z"
        fill="#F9FAFB"
      />
      <path
        d="M153.83 18.1309V26.246H149.477V9.51502H153.485V12.742C154.112 11.6769 155.02 10.8413 156.21 10.2357C157.4 9.63005 158.663 9.3269 160 9.3269V13.8388C158.788 13.7556 157.718 13.8124 156.79 14.0107C155.86 14.2097 155.135 14.6373 154.613 15.2957C154.091 15.9534 153.83 16.8987 153.83 18.1309H153.83Z"
        fill="#F9FAFB"
      />
      <path
        d="M0 0H7.49698V14.4313C7.49698 14.7005 7.77213 14.882 8.01948 14.776L25.7851 7.1591C26.0608 7.04092 26.2395 6.76976 26.2395 6.46974V4.44622e-05H33.7365V8.07661C33.7365 10.3268 32.3961 12.3604 30.3287 13.2468L27.559 14.4343C27.2657 14.56 27.2536 14.9716 27.5391 15.1144L30.6283 16.6596C32.5332 17.6125 33.7365 19.5602 33.7365 21.6908V30H26.2395V15.5687C26.2395 15.2995 25.9643 15.118 25.717 15.224L7.95141 22.8409C7.67575 22.9591 7.49702 23.2302 7.49702 23.5303V30H0V21.9234C0 19.6732 1.34043 17.6396 3.40782 16.7532L6.17747 15.5657C6.4708 15.44 6.48289 15.0284 6.19744 14.8856L3.10816 13.3404C1.20327 12.3876 0 10.4398 0 8.30925V0Z"
        fill="#2CD488"
      />
    </g>
    <defs>
      <clipPath id="clip0_144_14895">
        <rect width="160" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
