import { useMutation } from '@tanstack/react-query'

import { confirmEmail } from 'common/services/auth'

const useConfirmEmail = () => {
  const confirmEmailMutation = useMutation({
    mutationFn: confirmEmail,
  })

  return {
    confirmEmailMutation,
  }
}

export default useConfirmEmail
