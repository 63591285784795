import { XMarkIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

type DialogProps = {
  defaultState?: boolean
}

export enum ModalMode {
  INVITE = 'invite',
}

export interface ModalProps {
  isVisible: boolean
  show?: () => void
  hide: () => void
}

interface ModalIntProps extends ModalProps, React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
}

const Modal: React.FC<ModalIntProps> = (props) => {
  const { children, isVisible, hide } = props

  useEffect(() => {
    const eventCb = (e: any) => {
      if (e.key === 'Escape') {
        hide()
      }
    }

    document.addEventListener('keydown', eventCb)
    return () => {
      document.removeEventListener('keydown', eventCb)
    }
  }, [])

  return ReactDOM.createPortal(
    <div>
      {isVisible && (
        <div className="fixed top-0 right-0 left-0 z-50 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="relative p-6 rounded-lg bg-white">
            <button className="absolute top-2 right-2 text-secondary" onClick={() => hide()}>
              <XMarkIcon className="w-6 h-6" />
            </button>
            {children}
          </div>
        </div>
      )}
    </div>,
    document.body,
  )
}

export default Modal

export const useModal = ({ defaultState = false }: DialogProps) => {
  const [isVisible, setIsVisible] = useState(defaultState)

  const hide = () => {
    setIsVisible(false)
  }

  const show = () => {
    setIsVisible(true)
  }

  return {
    isVisible,
    show,
    hide,
  }
}
