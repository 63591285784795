import feedbackUrl from 'assets/images/feedback.png'

const height = 100
const TALLY_FORM_ID = import.meta.env.VITE_TALLY_FORM_ID

export default function FeedbackButton() {
  return (
    <button
      data-tally-open={TALLY_FORM_ID}
      data-tally-layout="modal"
      data-tally-width="750"
      data-tally-hide-title="1"
      data-tally-emoji-animation="wave"
      style={{
        backgroundImage: `url("${feedbackUrl}")`,
        backgroundColor: '#31C48D',
        position: 'fixed',
        right: 0,
        top: `calc(50vh - ${height / 2}px)`,
        width: '36px',
        height: `${height}px`,
      }}
    />
  )
}
