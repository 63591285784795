class CustomErrorHandler extends Error {
  private status = 500

  private errors: Array<any> = []

  private code = ''

  private args = {}

  constructor(message: string, status: number, errors: Array<any>, code: string) {
    // Calling parent constructor of base Error class.
    super()

    // Capturing stack trace, excluding constructor call from it.
    Error.captureStackTrace(this, this.constructor)

    // You can use any additional properties you want.
    // I'm going to use preferred HTTP status for this error types.
    // `500` is the default value if not specified.
    this.status = status || 500
    this.message = message || 'CustomErrorHandler'

    if (code) this.code = code

    if (errors) this.errors = !errors || Array.isArray(errors) ? errors : [errors]
  }
}

export default CustomErrorHandler
