export default {
  register: 'Register',
  login: 'Login',
  logout: 'Logout',
  session: 'Session',
  emailConfirmed: 'Email Confirmed',
  inviteConfirmed: 'Invite Confirmed',
  demandPlanFormStepSuccess: 'Demand Plan Form Step Success',
  viewDemandPlan: 'View Demand Plan',
  createDemandPlanStart: 'Create Demand Plan Start',
  createDemandPlanSuccess: 'Create Demand Plan Success',
  updateDemandPlanStart: 'Update Demand Plan Start',
  updateDemandPlanSuccess: 'Update Demand Plan Success',
  deleteDemandPlanSuccess: 'Delete Demand Plan Success',
  deleteActualsSuccess: 'Delete Actuals Success',
  uploadActualsSuccess: 'Upload Actuals Success',
  createUserSuccess: 'Create User Success',
}
