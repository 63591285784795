import React, { createContext, useContext, useState } from 'react'

import Modal, { useModal } from 'common/components/Modal'

type ConfirmationDialogProviderProps = {
  children: React.ReactNode
}

type confirmDialogArgs = {
  title: string
  message: string
  confirmText?: string
  onConfirm: () => void
}

const ConfirmationDialogContext = createContext<{
  showConfirmDialog: ({ title, message, onConfirm }: confirmDialogArgs) => void
  hideConfirmDialog: () => void
} | null>({
  showConfirmDialog: () => {},

  hideConfirmDialog: () => {},
})

export const useConfirmationDialog = () => {
  const context = useContext(ConfirmationDialogContext)

  if (!context) throw new Error('Confirmation Dialog context must be use inside ConfirmationDialogProvider')

  return context
}

const ConfirmationDialogProvider = (props: ConfirmationDialogProviderProps) => {
  const { isVisible, show, hide } = useModal({ defaultState: false })
  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')
  const [confirmButtonText, setConfirmButtonText] = useState('Confirm')
  const [onConfirm, setOnConfirm] = useState<() => void>(() => undefined)

  const showConfirmDialog = ({ title, message, onConfirm, confirmText }: confirmDialogArgs) => {
    show()
    setTitle(title)
    setMessage(message)
    if (confirmText && confirmText.length > 0) {
      setConfirmButtonText(confirmText)
    }
    setOnConfirm(() => {
      return () => {
        onConfirm()
        hide()
        setTitle('')
        setMessage('')
        setOnConfirm(() => () => undefined)
      }
    })
  }

  const hideConfirmDialog = () => {
    setTitle('')
    setMessage('')
    setOnConfirm(() => () => undefined)
    hide()
  }

  const { children } = props

  return (
    <ConfirmationDialogContext.Provider value={{ showConfirmDialog, hideConfirmDialog }}>
      <Modal isVisible={isVisible} hide={hide}>
        <div className="grid grid-cols-1 gap-4">
          <div className="font-bold text-lg">{title}</div>
          <div>{message}</div>
          <div>
            <button onClick={onConfirm} className="bg-primary-400 text-white text-sm p-2 rounded-md">
              {confirmButtonText}
            </button>
            <button onClick={hideConfirmDialog} className="bg-secondary text-sm ml-2 p-2 rounded-md">
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      {children}
    </ConfirmationDialogContext.Provider>
  )
}

export default ConfirmationDialogProvider
