import queryString from 'query-string'
import { createContext, useContext, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export type BreakdownsVisibility = Record<string, boolean>
export type BreakdownsFilters = Record<string, string[]>
export type FunnelVisibility = boolean
export type FunnelsFilters = string[]

export type FiltersAndVisibility = {
  breakdownsVisibility: BreakdownsVisibility
  breakdownsFilters: BreakdownsFilters
  funnelsVisibility: FunnelVisibility
  funnelsFilters: FunnelsFilters
  actualsVisibility: boolean
  conversionRateVisibility: boolean
}

const BreakdownFiltersContext = createContext<ReturnType<typeof useInit>>(null as any)

const useInit = () => {
  const [searchParams] = useSearchParams(
    new URLSearchParams(
      queryString.parse(window.location.search, { arrayFormat: 'separator', arrayFormatSeparator: '|' }) as Record<
        string,
        string
      >,
    ),
  )

  const [planDataVisibility, setPlanDataVisibility] = useState<FunnelVisibility>(
    searchParams.has('pv') ? searchParams.get('pv') === 'true' : true,
  )

  let initialBreakdownVisibility: BreakdownsVisibility = {}
  if (searchParams.has('bv')) {
    initialBreakdownVisibility = JSON.parse(decodeURIComponent(searchParams.get('bv') as string))
  }

  let initialBreakdownFilters: BreakdownsFilters = {}
  if (searchParams.has('bf')) {
    initialBreakdownFilters = JSON.parse(decodeURIComponent(searchParams.get('bf') as string))
  }

  const initialFunnelFilters: FunnelsFilters = []
  if (searchParams.has('ff')) {
    searchParams
      .get('ff')
      ?.split('|')
      .forEach((funnelFilter) => {
        initialFunnelFilters.push(funnelFilter)
      })
  }
  const [breakdownsVisibility, setBreakdownsVisibility] = useState<BreakdownsVisibility>(initialBreakdownVisibility)
  const [funnelsVisibility, setFunnelsVisibility] = useState<FunnelVisibility>(
    searchParams.has('fv') ? searchParams.get('fv') === 'true' : false,
  )
  const [breakdownsFilters, setBreakdownsFilters] = useState<BreakdownsFilters>(initialBreakdownFilters)
  const [funnelsFilters, setFunnelsFilters] = useState<FunnelsFilters>(initialFunnelFilters)
  const [actualsVisibility, setActualsVisibility] = useState<FunnelVisibility>(
    searchParams.has('av') ? searchParams.get('av') === 'true' : false,
  )

  const [conversionRateVisibility, setConversionRateVisibility] = useState<FunnelVisibility>(
    searchParams.has('cr') ? searchParams.get('cr') === 'true' : false,
  )

  return {
    breakdownsVisibility,
    setBreakdownsVisibility,
    breakdownsFilters,
    setBreakdownsFilters,
    funnelsVisibility,
    setFunnelsVisibility,
    funnelsFilters,
    setFunnelsFilters,
    actualsVisibility,
    setActualsVisibility,
    conversionRateVisibility,
    setConversionRateVisibility,
    planDataVisibility,
    setPlanDataVisibility,
  }
}

export function BreakdownFiltersProvider({ children }: { children?: React.ReactNode }) {
  return <BreakdownFiltersContext.Provider value={useInit()}>{children}</BreakdownFiltersContext.Provider>
}

export const useBreakdownFilters = () => {
  return useContext(BreakdownFiltersContext)
}
