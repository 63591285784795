import queryString from 'query-string'
import { createContext, useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { ChartTypes, DemandPlanSearchQueryParams } from 'pages/demand-plan/types'

import { BreakdownsFilters, FunnelsFilters, useBreakdownFilters } from '../BreakdownFilters/BreakdownFiltersProvider'

export type YearlySpreadType = 'quarterly' | 'monthly'

export enum Metrics {
  ConversionFunnel = 'Conversion Funnel',
  ConversionFunnelRate = 'Conversion Funnel Rate',
}

const compareOptions = ['None', 'Plan vs Actuals']

const DisplayOptionsContext = createContext<ReturnType<typeof useInit>>(null as any)

const useInit = () => {
  const {
    breakdownsFilters,
    breakdownsVisibility,
    funnelsVisibility,
    funnelsFilters,
    actualsVisibility,
    planDataVisibility,
    conversionRateVisibility,
  } = useBreakdownFilters()

  const [searchParams, setSearchParams] = useSearchParams(
    new URLSearchParams(
      queryString.parse(window.location.search, { arrayFormat: 'separator', arrayFormatSeparator: '|' }) as Record<
        string,
        string
      >,
    ),
  )

  const [chartBreakdownsFilters, setChartBreakdownsFilters] = useState<BreakdownsFilters>(
    searchParams.has('cbf')
      ? (JSON.parse(decodeURIComponent(searchParams.get('cbf') as string)) as BreakdownsFilters)
      : {},
  )

  const searchParamsSpreadType = searchParams.has('st') && searchParams.get('st') === 'm' ? 'monthly' : 'quarterly'
  const [yearlySpreadsType, setYearlySpreadsType] = useState<YearlySpreadType>(searchParamsSpreadType)

  const initialSelectedFunnel = searchParams.has('csf') ? searchParams.get('csf') : ''

  const [selectedFunnel, setSelectedFunnel] = useState<string>(initialSelectedFunnel || '')
  const [selectedFunnelStage, setSelectedFunnelStage] = useState<string>(
    searchParams.has('cfs') ? (searchParams.get('cfs') as string) : 'target',
  )

  const [chartFunnelsFilters, setChartFunnelsFilters] = useState<FunnelsFilters>(
    searchParams.has('cff') ? (searchParams.get('cff')?.split('|') as FunnelsFilters) : [],
  )

  const initialTabActiveIndex = searchParams.has('ai') && searchParams.get('ai') === '1' ? 1 : 0
  const [tabActiveIndex, setTabActiveIndex] = useState(initialTabActiveIndex)

  const [selectedCompare, setSelectedCompare] = useState(
    searchParams.has('cav') && searchParams.get('cav') === '1' ? compareOptions[1] : compareOptions[0],
  )

  const [selectedChartType, setSelectedChartType] = useState(
    searchParams.has('ct')
      ? // If there is a chart type value, use it
        (searchParams.get('ct') as ChartTypes)
      : // else use the default value based on the tab active index
        initialTabActiveIndex === 0
        ? ChartTypes.Bars
        : ChartTypes.Funnel,
  )

  const [selectedStackedBy, setSelectedStackedBy] = useState(
    searchParams.has('csb') ? (searchParams.get('csb') as string) : '',
  )

  const [selectedMetric, setSelectedMetric] = useState(
    searchParams.has('csm') ? (searchParams.get('csm') as Metrics) : Metrics.ConversionFunnel,
  )

  useEffect(() => {
    const object: DemandPlanSearchQueryParams = {
      ...Object.fromEntries(searchParams.entries()),
      bf: encodeURIComponent(JSON.stringify(breakdownsFilters)),
      fv: funnelsVisibility,
      av: actualsVisibility,
      pv: planDataVisibility,
      cr: conversionRateVisibility,
      bv: encodeURIComponent(JSON.stringify(breakdownsVisibility)),
      ff: funnelsFilters,
      ai: tabActiveIndex === 1 ? 1 : 0,
      cbf: encodeURIComponent(JSON.stringify(chartBreakdownsFilters)),
      ct: selectedChartType,
      cav: selectedCompare === compareOptions[1] ? 1 : 0,
      cff: chartFunnelsFilters,
      csf: selectedFunnel,
      csb: selectedStackedBy,
      csm: selectedMetric,
      cfs: selectedFunnelStage,
      st: yearlySpreadsType === 'monthly' ? 'm' : 'q',
    }

    setSearchParams(queryString.stringify(object, { arrayFormat: 'separator', arrayFormatSeparator: '|' }), {
      replace: true,
    })
  }, [
    selectedChartType,
    breakdownsFilters,
    selectedCompare,
    selectedFunnel,
    selectedMetric,
    selectedFunnelStage,
    selectedStackedBy,
    breakdownsVisibility,
    funnelsVisibility,
    actualsVisibility,
    planDataVisibility,
    conversionRateVisibility,
    funnelsFilters,
    tabActiveIndex,
    yearlySpreadsType,
    chartBreakdownsFilters,
    chartFunnelsFilters,
  ])

  return {
    chartBreakdownsFilters,
    setChartBreakdownsFilters,
    yearlySpreadsType,
    setYearlySpreadsType,
    selectedFunnel,
    setSelectedFunnel,
    selectedFunnelStage,
    setSelectedFunnelStage,
    selectedCompare,
    setSelectedCompare,
    selectedChartType,
    setSelectedChartType,
    selectedMetric,
    setSelectedMetric,
    tabActiveIndex,
    setTabActiveIndex,
    selectedStackedBy,
    setSelectedStackedBy,
    chartFunnelsFilters,
    setChartFunnelsFilters,
    searchParams,
  }
}

export function DisplayOptionsProvider({ children }: { children?: React.ReactNode }) {
  return <DisplayOptionsContext.Provider value={useInit()}>{children}</DisplayOptionsContext.Provider>
}

export const useDisplayOptions = () => {
  const context = useContext(DisplayOptionsContext)

  return context
}
