import { wrapUseRoutes } from '@sentry/react'

import 'error-polyfill'

import { ElementType, lazy, Suspense } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'

import ConfirmEmail from 'pages/auth/ConfirmEmail'
import ConfirmInvite from 'pages/auth/ConfirmInvite'
import { BreakdownFiltersProvider } from 'pages/demand-plan/components/BreakdownFilters/BreakdownFiltersProvider'
import { DisplayOptionsProvider } from 'pages/demand-plan/components/DisplayOptions/DisplayOptionsProvider'
import { DisplayTotalProvider } from 'pages/demand-plan/components/PlanTopSummary/DisplayTotalProvider'

import { Loader } from '../common/components/Loader'
import { AuthGuard, GuestGuard } from '../guards'

const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  )
}

const Login = Loadable(lazy(() => import('../pages/auth/Login')))
const Register = Loadable(lazy(() => import('../pages/auth/Register')))
const NewDemandPlan = Loadable(lazy(() => import('../pages/demand-plan/containers/NewDemandPlan')))
const EditDemandPlan = Loadable(lazy(() => import('../pages/demand-plan/containers/EditDemandPlan')))
const DemandPlanOutput = Loadable(lazy(() => import('../pages/demand-plan/containers/DemandPlanOutput')))
const NewDemandBudget = Loadable(lazy(() => import('../pages/demand-budget/containers/NewDemandBudget')))
const EditDemandBudget = Loadable(lazy(() => import('../pages/demand-budget/containers/EditDemandBudget')))
const ForgotPassword = Loadable(lazy(() => import('../pages/auth/ForgotPassword')))
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')))
const HomePage = Loadable(lazy(() => import('../pages/home')))
const AiHomePage = Loadable(lazy(() => import('../pages/ai')))
const SettingsPage = Loadable(lazy(() => import('../pages/settings')))
const AccountSettings = Loadable(lazy(() => import('../pages/settings/containers/AccountSettings')))
const UserSettings = Loadable(lazy(() => import('../pages/settings/containers/UserSettings')))
const AdminAccounts = Loadable(lazy(() => import('../pages/admin/Accounts')))
const AdminAccountUsers = Loadable(lazy(() => import('../pages/admin/AccountUsers')))
const AdminAccountDemandPlans = Loadable(lazy(() => import('../pages/admin/AccountDemandPlans')))
const DemandPlanSettings = Loadable(lazy(() => import('../pages/admin/DemandPlanSettings')))

const useSentryRoutes = wrapUseRoutes(useRoutes)

const Router = () => {
  return useSentryRoutes([
    {
      path: 'login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    {
      path: 'forgot-password',
      element: (
        <GuestGuard>
          <ForgotPassword />
        </GuestGuard>
      ),
    },
    {
      path: 'register',
      element: (
        <GuestGuard>
          <Register />
        </GuestGuard>
      ),
    },
    {
      path: 'auth/confirm/email',
      element: (
        <GuestGuard>
          <ConfirmEmail />
        </GuestGuard>
      ),
    },
    {
      path: 'auth/confirm/invite',
      element: (
        <GuestGuard>
          <ConfirmInvite />
        </GuestGuard>
      ),
    },
    {
      path: 'auth/reset-password',
      element: (
        <GuestGuard>
          <ResetPassword />
        </GuestGuard>
      ),
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <HomePage />
        </AuthGuard>
      ),
    },
    {
      path: '/ai',
      element: (
        <AuthGuard>
          <AiHomePage />
        </AuthGuard>
      ),
    },
    {
      path: '/new-revenue-plan',
      element: (
        <AuthGuard>
          <NewDemandPlan />
        </AuthGuard>
      ),
    },
    {
      path: '/revenue/:id',
      element: (
        <AuthGuard>
          <BreakdownFiltersProvider>
            <DisplayOptionsProvider>
              <DisplayTotalProvider>
                <DemandPlanOutput />
              </DisplayTotalProvider>
            </DisplayOptionsProvider>
          </BreakdownFiltersProvider>
        </AuthGuard>
      ),
    },
    {
      path: '/revenue/:id/edit',
      element: (
        <AuthGuard>
          <EditDemandPlan />
        </AuthGuard>
      ),
    },
    {
      path: '/revenue/:id/new-budget',
      element: (
        <AuthGuard>
          <NewDemandBudget />
        </AuthGuard>
      ),
    },
    {
      path: '/budget/:id/edit',
      element: (
        <AuthGuard>
          <EditDemandBudget />
        </AuthGuard>
      ),
    },
    {
      path: '/settings',
      children: [
        { index: true, element: <Navigate to="user" /> },
        { path: 'account', element: <AccountSettings /> },
        { path: 'user', element: <UserSettings /> },
      ],
      element: (
        <AuthGuard>
          <SettingsPage />
        </AuthGuard>
      ),
    },
    {
      path: '/admin',
      element: (
        <AuthGuard adminOnly>
          <AdminAccounts />
        </AuthGuard>
      ),
    },
    {
      path: '/admin/account/:accountId',
      element: (
        <AuthGuard adminOnly>
          <AdminAccountDemandPlans />
        </AuthGuard>
      ),
    },
    {
      path: '/admin/account/:accountId/users',
      element: (
        <AuthGuard adminOnly>
          <AdminAccountUsers />
        </AuthGuard>
      ),
    },
    {
      path: '/admin/account/:accountId/plan/:planId',
      element: (
        <AuthGuard adminOnly>
          <DemandPlanSettings />
        </AuthGuard>
      ),
    },
    {
      path: '*',
      element: (
        <div className="flex justify-center p-10">404. Looks like the page you are requesting is not there.</div>
      ),
    },
  ])
}

const App = () => <Router />

export default App
