import { createContext, useContext, useState } from 'react'

const DisplayTotalContext = createContext<ReturnType<typeof useInit>>(null as any)

const useInit = () => {
  const [displayTotal, setDisplayTotal] = useState<number>(0)

  return {
    displayTotal,
    setDisplayTotal,
  }
}

export function DisplayTotalProvider({ children }: { children?: React.ReactNode }) {
  return <DisplayTotalContext.Provider value={useInit()}>{children}</DisplayTotalContext.Provider>
}

export const useDisplayTotal = () => {
  return useContext(DisplayTotalContext)
}
